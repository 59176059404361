import {
  reactive,
} from '@vue/composition-api'

export default reactive({
  currentPage: null,
  perPage: null,
  totalRows: null,
  logList: [],
  jobList: [],
  campaignList: [],
  fileList: [],
  isBusy: false,
  filterParams: {},
  sortBy: '-idx',
  isLoading: false,
})
