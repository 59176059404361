<template>
  <div>
    <TagbyFilterV2
      :initialParams="state.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    />

    <TagbyList
      v-model="state.currentPage"
      :items="getters.items"
      :fields="tableColumns"
      :busy="state.isBusy"
      :current-page="state.currentPage"
      :total-rows="state.totalRows"
      :per-page="state.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'screenshot-log-detail', params: { idx: data.value} }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(file)="data">
        <a
          v-if="data.item.file_url != null"
          href="#"
          @click="downloadFile(data.item.file_url)"
        >다운로드</a>
        <span v-else>
          없음
        </span>
      </template>

      <template #cell(campaign)="data">
        <div v-if="data.item.campaign_idx != null">
          {{ data.item.campaign_title }}
          (<ComptIdxColumn :campaignIdx="data.item.campaign_idx" />)
        </div>
      </template>

      <template #cell(state)="data">
        <div
          style="width: 80px"
          :class="data.value === 'ACTIVE' ? 'text-success' : null"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
  BButton,
} from 'bootstrap-vue'
import useInitialize from './useInitialize'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import state from './state'
import getters from './getters'
import downloadFile from '@/utils/downloadFile'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'

export default defineComponent({
  components: {
    BLink,
    BButton,
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
    ComptIdxColumn,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    } = useItems()
    searchList()

    return {
      state,
      getters,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
      downloadFile,
    }
  },
})
</script>
