import {
  computed,
} from '@vue/composition-api'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'
import {
  values,
} from '@/utils/value'

import state from './state'
import api from './api'
import getters from './getters'

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: state.currentPage,
    size: state.perPage,
    total: state.totalRows,
    ...state.filterParams,
  }))

  const categoryOptions = [
    {
      label: 'JOB_IDX',
      paramName: 'job_idx_list',
      format: 'number',
    },
    {
      label: 'CAMPAIGN_IDX',
      paramName: 'campaign_idx_list',
      format: 'number',
    },
    {
      label: 'SEARCH_STR',
      paramName: 'search_str_list',
      format: 'text',
    },
    {
      label: 'RUN',
      paramName: 'run_list',
      format: 'number',
    },
    {
      label: 'TAB',
      paramName: 'tab_list',
      format: 'checkbox',
      options: [
        { text: 'ALL', value: 'ALL' },
        { text: 'INFLUENCER', value: 'INFLUENCER' },
        { text: 'VIEW', value: 'VIEW' },
      ],
    },
    {
      label: 'HAS_SCREENSHOT',
      paramName: 'has_screenshot_list',
      format: 'checkbox',
      options: [
        { text: 'TRUE', value: true },
        { text: 'FALSE', value: false },
      ],
    },
    // {
    //   label: 'CREATED_AT',
    //   paramName: 'created_at',
    //   format: 'date',
    // },
  ]

  const searchLogList = () => api.searchList({
    ...searchParams.value,
    sort_by: state.sortBy,
  }).then(response => {
    const resData = response.data
    state.logList = resData.data
    state.totalRows = resData.page_info.total_count
    state.filterParams = {
      ...state.filterParams,
      total: resData.page_info.total_count,
    }
  }).catch(() => {
    makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
  })

  const searchJobList = () => api.searchJobList({
    job_idx_list: getters.jobIdxList,
  }).then(response => {
    const resData = response.data
    state.jobList = resData.data
  })

  const searchCampaignList = () => api.searchCampaignList({
    campaign_idx_list: getters.campaignIdxList,
  }).then(response => {
    const resData = response.data
    state.campaignList = resData.data
  })

  const searchFileList = () => api.searchFileList({
    file_idx_list: getters.fileIdxList,
  }).then(response => {
    const resData = response.data
    state.fileList = resData.data
  })

  const searchList = () => {
    state.isBusy = true
    return searchLogList()
      .then(() => searchJobList())
      .then(() => searchCampaignList())
      .then(() => searchFileList())
      .finally(() => {
        state.isBusy = false
      })
  }

  const search = params => {
    state.currentPage = 1
    state.filterParams = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    searchList()
  }

  const tableColumns = [
    { key: 'idx', label: 'idx', sortable: true },
    { key: 'file', label: 'file' },
    { key: 'campaign', label: 'campaign' },
    { key: 'job_idx', label: 'job_idx' },
    { key: 'run', label: 'run' },
    { key: 'search_str', label: 'search_str' },
    { key: 'tab', label: 'tab' },
    // { key: 'is_screenshot', label: 'has_screenshot' },
    // { key: 'has_result', label: 'has_result' },
    { key: 'best_ranking', label: 'best_ranking' },
    { key: 'state', label: 'state' },
    { key: 'created_at', label: 'created_at' },
  ]

  const changePage = params => {
    state.currentPage = params.page
    searchList()
  }

  const changeSort = ctx => {
    state.sortBy = ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`
    searchList()
  }

  return {
    categoryOptions,
    tableColumns,
    searchList,
    search,
    changePage,
    changeSort,
  }
}
