import {
  reactive,
  computed,
  watch,
} from '@vue/composition-api'

import state from './state'

import {
  values,
  indexing,
  grouping,
} from '@/utils/value'

const jobIdxList = computed(() => values(state.logList, 'job_idx'))
const campaignIdxList = computed(() => values(state.jobList, 'campaign_idx').filter(e => e != null))
const fileIdxList = computed(() => values(state.logList, 'file_idx').filter(e => e != null))

const items = computed(() => {
  const jobByIdx = indexing(state.jobList, 'idx')
  const campaignByIdx = indexing(state.campaignList, 'idx')
  const fileByIdx = indexing(state.fileList, 'idx')

  return state.logList.map(e => {
    const job = jobByIdx[e.job_idx]
    if (job == null) {
      return e
    }
    return {
      ...e,
      campaign_idx: job.campaign_idx,
      adv_idx: job.adv_idx,
    }
  }).map(e => {
    const campaign = campaignByIdx[e.campaign_idx]
    if (campaign == null) {
      return e
    }
    return {
      ...e,
      campaign_title: campaign.title,
    }
  }).map(e => {
    if (e.file_idx == null) {
      return e
    }
    const file = fileByIdx[e.file_idx]
    if (file == null) {
      return e
    }
    return {
      ...e,
      file_url: file.url,
    }
  })
})

export default reactive({
  items,
  jobIdxList,
  campaignIdxList,
  fileIdxList,
})
